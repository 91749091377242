<template>
  <div class="information w100 autobox2">
    <div class="dp-f ai-c jc-sb">
      <div class="fs-20 fw-b">消息通知</div>
      <div class="c-999 fs-14 dp-f ai-c jc-sb cu-p" @click="router_push_name('message',undefined,undefined,true)">
        查看更多
        <el-image :src="require('@/assets/img/home/right.png')" />
      </div>
    </div>
    <div class="mt-20 fs-14">
      <nodata v-if="state.Notice.length==0"/>
      <div v-else class="row cu-p mb-18" v-for="(item,index) in state.Notice" :key="index" @click="router_push_name('message',undefined,undefined,true)">
        <div class="br50 bg-f00 wh6 mr-8" v-if="item.status==0"></div>
        <div>{{item.messageTitle}}</div>
        <div class="pos-a" style="right:0">{{item.messageTime}}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref,reactive} from "vue"
import { router_push_name } from "@/utils/server/router.js"
import { httpToken } from "@/utils/request";
import nodata from "@/components/layout/nodata.vue"
import qs from "qs";
const loading = ref(false);//loading 显示
const state = reactive({
  Notice:[]
})
// 获取数据
const getData = () => {
	state.Notice = []
	loading.value = true
  // status 0 未读 1已读
	httpToken({
		method: "post",
		url: '/admin/messageuser/list',
    data:qs.stringify({
      rows:3
    })
	}).then((res) => {
		state.Notice = res.data.records
		loading.value = false;
	}).catch((err)=>{
		loading.value = false;
	})
}
getData()
</script>

<style lang="scss" scoped>
.information {
  padding: 30px 30px 23px;
  background-color: #fff;
  height: 244px;
  .row {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px #EDEDED solid;
    position: relative;
  }
  .wh6 {
    width: 6px;
    height: 6px;
  }
}
</style>