<template>
  <div class="nodata ta-c">
    <img class="mb-12 w-138 h-122" src="@/assets/img/general/nodata.png">
    <div class="title">{{ props.title }}</div>
  </div>
</template>
<script setup>
import { ref, reactive } from "vue"
const props = defineProps({
  // 定时器执行时间
  Imagetype: {
    type: Number,
    default: () => { return 1 }
  },
  // css 类型
  title: {
    type: String,
    default: () => { return '当前暂无最新消息通知～' }
  },
});
</script>
<style lang="scss" scoped>
.nodata {
  margin: 0 auto;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
</style>